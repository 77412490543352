<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <remote-devices />
        <device-info-generic-banner />
      </b-col>
    </b-row>

    <b-row class="match-height" 
    v-if="this.$roles.isAdmin(this.user) || this.$roles.isClientRemote(this.user, this.currentDevicerRoles) || this.$roles.isTechnicianRemote(this.user, this.currentDevicerRoles)  ">
      <b-col lg="12" sm="12">
        <remote-actions />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <remote-programas />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import RemoteDevices from "../Remote-2/RemoteDevices.vue";
import RemoteProgramas from "./RemoteProgramas.vue";
import RemoteCommandFeedback from "./RemoteCommandFeedback.vue";
import RemoteActions from "./RemoteActions.vue";
import DeviceInfoGenericBanner from "@/views/generic-device/DeviceInfoGenericBanner.vue";

export default {
  components: {
    BRow,
    BCol,
    RemoteDevices,
    RemoteProgramas,
    RemoteCommandFeedback,
    RemoteActions,
    DeviceInfoGenericBanner
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.users.currentUser;
    },
    currentDevice() {
      return this.$store.state.devices.currentDevice;
    },
    currentDevicerRoles()
    {
      return this.$store.state.devices.currentDevicerRoles
    },
  },
  methods:{
    getRecipes(){
      this.$store
        .dispatch("recipes/getRecipes", { context: this })
    },
    getSingleDeviceRole(){
      this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
      });
    },
  },
  created() {
    setTimeout(() => {
      this.getRecipes()
      this.getSingleDeviceRole()
    }, 500);
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-remote.scss";
@import "@core/scss/vue/pages/page-alerts.scss";
@import "@core/scss/vue/pages/page-device.scss";
</style>
