<template>
  <div>
    <b-row>
      <b-col cols="12" xl="4">
        <b-card no-body>
          <b-card-body>
            <div class="device-img">
              <img :src="`${imageS3}${device.image}`" alt="" style="width: 100%; object-fit: cover;" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" xl="8">
        <b-card no-body class="alerts-status">
          <b-card-body>
            <b-row>
              <b-col :xl="isColdRoom ? '6':'4'" class="status-side-device">
                <b-card-title class="text-status mb-0"
                  >{{ $t("Device Status") }}
                </b-card-title>
                <div class="alert-device-icons">
                  <div class="alert-icon-device" style="display: block;
margin: 0 auto;">
                    <img v-if="device.status === 'OK!'" src="@/assets/images/icons/ok.png" alt="" />
                    <img v-else-if="device.status == 'NOK!'" src="@/assets/images/icons/nok.png" alt="" />
                    <img v-else-if="device.status == 'Ups!'" src="@/assets/images/icons/ups.png" alt="" />
                    <span class="alert-device-text">{{ device.status }}</span>
                  </div>
                </div>
              </b-col>
              <b-col v-if="!isColdRoom"  class="status-side" xl="4">
                <b-card-title class="text-status mb-0"
                  >{{ $t("Current Recipe") }}
                </b-card-title>
                <div class="alert-receita">
                  <div class="alert-receita-icon" v-if="show_img_recipe">
                    <img
                      :src="imageRecipe"
                      alt=""
                      width="60"
                      height="61" style="display: block;
margin: 0 auto;"
                    />
                    <span>{{ $t(recipe_name) }}</span>
                  </div>
                </div>
              </b-col>
              <b-col class="status-side" :xl="isColdRoom ?'6':'4'">
                <b-card-title class="text-status mb-0"
                  >{{ $t("Temperature") }}
                </b-card-title>
                <div class="alert-temps">
                  <div class="alert-temp">
                    <!-- <vue-apex-charts
                      type="radialBar"
                      height="250"
                      :options="goalOverviewRadialBar.chartOptions"
                      :series="[goalOverviewRadialBar.series]"
                    /> -->
                    <div class="temp-range">
                      <!-- <span > {{ goal_overview.min_temp }} {{show_temps ? '°C' : '123'}} </span> -->
                      <span ></span>
                      <img src="@/assets/images/icons/thermometer.png" alt="" style="max-width: 64px; max-height: 64px; margin-top: 20%;"/>
                      <span style="font-size: 35px; margin-top:20%;"
                        >{{
                          current_temp ? current_temp.toLocaleString("default", {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }):""
                        }}°C</span
                      >
                      <span ></span>
                      <!-- <span > {{ goal_overview.max_temp }} {{show_temps ? '°C' : ''}} </span> -->
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";


export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VueApexCharts,
    vSelect
  },
  directives: {
    Ripple,
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    device() {
      return this.$store.state.devices.currentDevice;
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
    recipes() {
      return this.$store.state.recipes.recipes;
    },
    imageRecipe(){
      if(!this.recipe_img)
        return require("@/assets/images/receitas/default.png")
      else
        return `${this.imageS3}${this.recipe_img}`

    },
    isColdRoom(){
      return this.device.typology == "Cold Room"
    }
  },
  data() {
    return {
      show_temps: false,
      show_img_recipe: false,
      recipe_name: "",
      recipe_img: "",
      goal_overview: {
        min_temp: 0,
        max_temp: 0,
      },
      current_temp: 0,
      goalOverviewRadialBar: {
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#fff"],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "50%",
              },
              track: {
                background: "rgba(255, 255, 255, 0.5)",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  show: false,
                  offsetY: 30,
                  color: "#fff",
                  fontSize: "18px",
                },
                value: {
                  show: false,
                  offsetY: -15,
                  color: "#fff",
                  fontSize: "29px",
                },
              },
            },
          },
          legend: { show: false },
          fill: {
            colors: "#fff",
          },
          grid: {
            padding: {
              bottom: 0,
              top:-10
            },
          },
          labels: [""],
        },
      },
    };
  },
  watch: {
    currentMetricGenericDevice(oldVal, newVal) {
      this.getData();
      this.getRecipe();
    },
  },
  methods: {
    goToAlerts() {
      this.$router.push({
        name: "device-alerts",
        params: { id: this.$route.params.id },
      });
    },
    goToReports() {
      this.$router.push({
        name: "device-reports",
        params: { id: this.$route.params.id },
      });
    },
    anchor(event) {
      let id = event.target.closest(".device-btn").dataset.id;
      if (id) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }
    },
    get_percent(current, min, max) {
      let percent = 0
      let val1 = current - min;
      let val2 = max - min;
      if (val2 == 0)
        percent = (val1 * 100).toFixed(2);
      else 
        percent = ((val1 / val2) * 100).toFixed(2);
      if (percent > 0 && percent < 100)
        return percent
      else if (percent <= 0)
        return -1
      else
        return 100
    },
    getData() {
      let percent = this.show_temps ? this.get_percent(
        this.currentMetricGenericDevice.current_internal_temp,
        this.goal_overview.min_temp,
        this.goal_overview.max_temp
      ) : 0;
      this.goalOverviewRadialBar.series = [percent];
      this.current_temp = this.currentMetricGenericDevice.current_internal_temp;
    },
    getRecipe() {
      let last_updated_recipe = Number(
        this.currentMetricGenericDevice.current_recipe
      ).toFixed(0);
      if (last_updated_recipe === 200) last_updated_recipe = 1;
      let recipe_obj = this.recipes.find((x) => x.code == last_updated_recipe);
      this.recipe_name = recipe_obj ? recipe_obj.name : this.$t("Default");
      this.recipe_img = recipe_obj ? recipe_obj.image : "";
      this.show_img_recipe = true;
      this.goal_overview.min_temp = recipe_obj ? recipe_obj.min_temp : "";
      this.goal_overview.max_temp = recipe_obj ? recipe_obj.max_temp : "";
      this.show_temps = recipe_obj ? true : false;
    },
    getSingleDevice() {
      this.$store.dispatch("devices/getSingleDevice", {
        context: this,
        id: this.$route.params.id,
      });
      this.getSingleDeviceRole()
      this.getGenericMetric()
    },
    getSingleDeviceRole(){
      this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    },
    getGenericMetric(){
      this.$store.dispatch("devices/getGenericDevicelMetric", {
      context: this,
      id: this.$route.params.id,
    });
    }
  },
  created() {
    this.getSingleDevice();
    this.$store.dispatch("recipes/getRecipes", { context: this });
  },
};
</script>
