<template>
  <b-card-code class="food-carousel-wrapper">
    <swiper
      class="swiper-responsive-breakpoints food-carousel"
      :options="swiperOptions"
      ref="swiperFood"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide v-for="(data, index) in swiperData" :key="index">
        <b-img class="carousel-img" :src="data.img" fluid />
        <span class="carousel-text">{{ data.text }}</span>
      </swiper-slide>

      <!-- <div slot="pagination" class="swiper-pagination" /> -->
    </swiper>
    <b-button
      class="new-program-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-center
      variant="none"
    >
      <span>Create new program</span>
    </b-button>
    <b-modal id="modal-center" centered ok-only ok-title="Guardar">
      <div
        v-if="!editTitleProgram"
        class="d-flex flex-column flex-md-row align-items-center"
      >
        <span class="modal-title" @click="toggleEditTitleProgram">{{
          titleProgram
        }}</span>
        <b-button
          class="btn btn-primary ml-md-1"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-center
          variant="none"
          @click="toggleEditTitleProgram"
        >
          <span>Editar</span>
        </b-button>
      </div>
      <b-row v-if="editTitleProgram">
        <b-col cols="12">
          <b-form-group>
            <div class="d-flex flex-column flex-md-row align-items-center">
              <b-form-input
                id="titleProgram"
                :placeholder="titleProgram"
                v-model="titleProgram"
              />
              <b-button
                class="btn btn-primary ml-md-1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-center
                variant="none"
                @click="toggleEditTitleProgram"
              >
                <span>OK</span>
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <v-select
          class="modal-select"
          id=""
          v-model="selectedProgram"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :selectable="(option) => !option.value.includes('select_currency')"
          :options="programName"
          :searchable="false"
          label="text"
        />
      </b-form-group>
      <b-row>
        <b-col cols="4">
          <b-form-group :label="$t('Min. Temp.')">
            <b-form-input id="basicInput" placeholder="0000" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group :label="$t('Max. Temp.')">
            <b-form-input id="basicInput" placeholder="0000" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <template #code>
      {{ codeResponsive }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BRow,
  BCol,
  BImg,
  BButton,
  BFormGroup,
  BFormInput,
  VBModal,
  BModal,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { codeResponsive } from "./code";

export default {
  components: {
    BRow,
    BCol,
    Swiper,
    SwiperSlide,
    BImg,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    BCardCode,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      codeResponsive,
      titleProgram: "Novo Programa 001",
      titleProgramBefore: "",
      editTitleProgram: false,
      selectedProgram: "New recipe",
      programName: [
        // { value: 'select_currency', text: 'Select Currency' },
        { value: "BAR_DRINKS", text: "Back Bar & Drinks" },
        { value: "BACKERY_PASTRY", text: "Backery, Pastry & Ice Cream" },
        { value: "FISH", text: "Fish" },
        { value: "GASTRONOMY", text: "Gastronomy" },
        { value: "PIZZA", text: "Pizza & Prep" },
      ],
      /* eslint-disable global-require */
      swiperData: [
        {
          img: require("@/assets/images/receitas/Back Bar & Drinks.svg"),
          text: "Back Bar & Drinks",
        },
        {
          img: require("@/assets/images/receitas/Bakery Pastry & Ice Cream.svg"),
          text: "Backery, Pastry & Ice Cream",
        },
        {
          img: require("@/assets/images/receitas/Fish.svg"),
          text: "Fish",
        },
        {
          img: require("@/assets/images/receitas/Gastronomy.svg"),
          text: "Gastronomy",
        },
        {
          img: require("@/assets/images/receitas/Pizza & Prep.svg"),
          text: "Pizza & Prep",
        },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        activeIndex: 2,
        centeredSlides: true,
        slidesPerView: 5,
        spaceBetween: 50,
        loop: false,
        preventClicks: false,
        preventClicksPropagation: false,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  methods: {
    toggleEditTitleProgram() {
      if (this.titleProgram) {
        this.titleProgramBefore = this.titleProgram;
        this.titleProgram = "";
      }
      if (this.editTitleProgram) {
        this.titleProgram = this.titleProgramBefore;
      }
      this.editTitleProgram = !this.editTitleProgram;
    },
  },
  mounted() {
    console.log((this.$refs.swiperFood.$swiper.activeIndex = 2));
  },
};
</script>