<template>
  <div class="table-program-list">
    <b-row>
      <b-col class="mb-1">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-input-group class="input-group-merge search-list ml-0">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="filteredRecipeName"
               :placeholder="$t('Program Name')" />
            </b-input-group>
            <b-button class="search-btn mr-0" variant="none" @click="getTotalRecipes(filteredRecipeName)">
              <span>{{ $t("GO") }}</span>
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <vue-good-table
      :columns="columns"
      :rows="totalRecipes"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: 0,
        perPageDropdown: 0,
        nextLabel:$t('Next'),
        prevLabel:  $t('Prev'),
        rowsPerPageLabel: $t('Rows per page'),
        ofLabel: $t('of'),
      }"
     @on-cell-click="openModalEdit"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'image'" >
          <span class="text-nowrap" v-if="props.row.image">
            <img
              :src="`${imageS3}${props.row.image}`"
              alt=""
              class="program-image"
            />
          </span>
          <span class="text-nowrap" v-else>
            <img src="https://fakeimg.pl/60x60/" class="program-image" />
          </span>
        </span>
        <span v-else-if="props.column.field === 'synced'" class="text-nowrap">
          <span
            class="text-nowrap program center"
            :class="{ updated: props.row.synced == false }"
          >
            <b-button
              class="devices-btn active mb-0 p-0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="none"
            >
              <img src="@/assets/images/icons/refresh.svg" alt="" />
            </b-button>
          </span>
        </span>

        <span v-else-if="props.column.field === 'mode'" class="text-nowrap">
          <b-badge
            :variant="statusVariant(props.row.mode)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-center-mode
          >
            {{ props.row.mode }}
          </b-badge>
        </span>
      </template>

      <!-- pagination -->
      <!-- <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to 7 </span>
            <span class="text-nowrap"> of 100 entries </span>
          </div>
          <div class="d-flex flex-wrap">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="pagination-btn mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template> -->
    </vue-good-table>

    <div class="d-flex flex-column align-items-end">
      <div class="d-flex align-items-end">
        <b-button
        class="new-program-btn blue mt-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-center-sync
        variant="none"
      >
        <span>{{$t("Sync programs to device")}}</span>
      </b-button>
        <b-button
          class="new-program-btn mt-1"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-center-new
          variant="none"
          :disabled="btnDisabled"
        >
          <span>{{$t("Create new program")}}</span>
        </b-button>
      </div>
    </div>

    <!--NEW PROGRAM-->
    <b-modal
      id="modal-center-new"
      content-class="two-btns"
      centered
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Save')"
      @ok="validationNew"
      @cancel="cleanData"
    >
      <span class="modal-title">{{ $t("Create your new program") }}</span>
      <b-row>
        <b-col cols="12">
          <validation-observer ref="newForm">
          <b-form-group>
            <div class="align-items-center mt-1">
              <div class="pl-md-2">
                <b-form-group :label="$t('Program Name')">
                   <validation-provider
                          #default="{ errors }"
                          name="Program Name"
                          rules="required"
                        >
                  <b-form-input
                    placeholder="Name"
                    id="titleProgram"
                    v-model="titleProgram"
                  />
                   <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                </b-form-group>
                <b-row v-if="currentDevice.controller === 'EVCO'">
                  <b-col cols="6">
                    <b-form-group :label="$t('Min. Temp.')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Min. Temp.')"
                          :rules="`required|between:${currentDevice.min_allowed_temp},${currentDevice.max_allowed_temp}`"
                        >
                      <b-form-select
                        placeholder="0.0"
                        id="minTempProgram"
                        v-model="minTempProgram"
                        :options="rangeTemp(currentDevice.min_allowed_temp, currentDevice.max_allowed_temp)"
                      >
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('Max. Temp.')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Max. Temp.')"
                          :rules="`required|between:${minTempProgram},${currentDevice.max_allowed_temp}`"
                        >
                      <b-form-select
                        placeholder="0.0"
                        id="maxTempProgram"
                        v-model="maxTempProgram"
                        type="number"
                        :options="rangeTemp(minTempProgram, currentDevice.max_allowed_temp)"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="currentDevice.controller === 'DIXEL'">
                  <b-col cols="12">
                    <b-form-group :label="$t('Set point')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Set Point')"
                          rules="required"
                        >
                      <b-form-select
                        placeholder="0.0"
                        id="setPointProgram"
                        v-model="setPointProgram"
                        type="number"
                        :options="rangeTemp(currentDevice.min_allowed_temp, currentDevice.max_allowed_temp)"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form-group>
        </validation-observer>
        </b-col>
      </b-row>
    </b-modal>

    <!--EDIT PROGRAM-->
    <b-modal
      id="modal-center-edit"
      ref="modal-center-edit"
      content-class="two-btns"
      centered
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Save')"
      :ok-disabled="!isCustomRecipe(editRowData)"
      @ok="validationEdit"
      @cancel="cleanData"
    >
      <span class="modal-title" v-if="isCustomRecipe(editRowData)">{{ $t("Edit Program") }}</span>
      <b-row v-if="isCustomRecipe(editRowData)">
        <b-col cols="13">
          <validation-observer ref="editForm">
          <b-form-group>
            <div class="align-items-center mt-1">
              <div class="pl-md-2">
                <b-form-group :label="$t('Program Name')">
                  <validation-provider
                          #default="{ errors }"
                          name="Program Name"
                          rules="required"
                        >
                  <b-form-input
                    id="titleProgram"
                    v-model="editRowData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                </b-form-group>
                <b-row v-if="currentDevice.controller === 'EVCO'" style="flex-flow: nowrap;">
                  <b-col cols="6">
                    <b-form-group :label="$t('Min. Temp.')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Min. Temp.')"
                          :rules="`required|between:${currentDevice.min_allowed_temp},${currentDevice.max_allowed_temp}`"
                        >
                      <b-form-select
                        placeholder="0.0"
                        id="minTempProgram"
                        v-model="editRowData.min_temp"
                        :options="rangeTemp(currentDevice.min_allowed_temp, currentDevice.max_allowed_temp)"
                      >
                    </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="7">
                    <b-form-group :label="$t('Max. Temp.')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Max. Temp.')"
                          :rules="`required|between:${editRowData.min_temp},${currentDevice.max_allowed_temp}`"
                        >
                      <b-form-select
                        id="maxTempProgram"
                        v-model="editRowData.max_temp"
                        :options="rangeTemp(editRowData.min_temp, currentDevice.max_allowed_temp)"
                      />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="currentDevice.controller === 'DIXEL'">
                  <b-col cols="12">
                    <b-form-group :label="$t('Set point')">
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Set Point')"
                          rules="required"
                        >
                      <b-form-select
                        placeholder="0.0"
                        id="setPointProgram"
                        v-model="editRowData.set_point"
                        type="number"
                        :options="rangeTemp(currentDevice.min_allowed_temp, currentDevice.max_allowed_temp)"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
      <span class="modal-title" v-else>{{ $t("You cannot change default programs (Only Custom Programs)")}}</span>
      <b-button
        class="delete-program-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        @click="toggleModal"
        variant="none"
        v-if="isCustomRecipe(editRowData)"
      >
        <feather-icon class="delete-program-icon" icon="Trash2Icon" />
      </b-button>
    </b-modal>

    <!--SYNC PROGRAMS-->
    <b-modal
      id="modal-center-sync"
      content-class="two-btns"
      centered
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Sync')"
      @ok="syncPrograms"
      @cancel="cleanData"
    >
      <span class="modal-title">{{ $t("Sync Your Programs") }}</span>
      <b-row>
        <b-col cols="12">
          <p class="program-info">
            {{ $t("You are syncing the following programs") }}:
          </p>
          <b-form-group 
            v-for="programs in programsToSync"
            :key="programs">
            <div class="d-flex flex-column flex-md-row align-items-center mb-1">
            <img src="https://fakeimg.pl/75x75/" class="program-image" />
              <div class="pl-md-2">
                <p class="m-0 mb-1">{{programs.name}}</p>
                <p class="m-0">{{programs.temp_range}}</p>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!--MODAL CONFIRM DELETE-->
    <b-modal
      ref="delete"
      id="modal-center-delete"
      centered
      cancel-title="No"
      ok-title="Yes"
      content-class="two-btns"
      @ok="deleteProgram"
      @cancel="cleanData"
    >
      <span class="modal-title">{{$t("Confirmation")}}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
          <p>{{$t("Are you sure you want to delete this program?")}}</p>
        </b-col>
      </b-row>
    </b-modal>

    <!--MODE MODAL-->
    <b-modal
      id="modal-center-mode"
      centered
      :cancel-title="$t('No')"
      :ok-title="$t('Yes')"
      content-class="two-btns"
      @ok="setRecipeCommand"
      @cancel="cleanData"
    >
      <span class="modal-title">{{$t("Program change")}}</span><br><br>
      <b-row>
        <b-col cols="12" class="mt-1">
          <p>{{$t("Do you really want to swap to program ")}} <strong>{{editRowData.name}}</strong> ? </p> <br><br>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  VBTooltip,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { codeBasic } from "@/views/home/home-1/code";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required , between} from "@validations";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    VueGoodTable,
    BBadge,
    VBTooltip,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      editRowData: "",
      programName: "",
      minTempProgram: null,
      maxTempProgram: null,
      setPointProgram: null,
      titleProgram: "",
      selectedProgram: "",
      selectedDevicesSearch: null,
      perPage: 1,
      pageLength: 5,
      pageOptions: [5, 10, 20],
      dir: false,
      totalRows: 3,
      currentPage: 1,
      codeBasic,
      columns: [
        {
          label: this.$t("IMAGE"),
          field: "image",
          width: "120px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: this.$t("CATEGORY"),
          field: "category",
        },
        {
          label: this.$t("PROGRAM DESCRIPTION"),
          field: "name",
        },
        {
          label: this.$t("TEMP.RANGE"),
          field: "temp_range",
          width: "250px",
        },
        {
          label: this.$t("STATUS"),
          field: "synced",
          width: "125px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: this.$t("MODE"),
          field: "mode",
          width: "125px",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      exemple6: [
        {
          1: "Ok!",
          2: "Off",
        },
        {
          1: "light-activo",
          2: "light-offline",
        },
      ],
      required,
      between,
      register:0,
      filteredRecipeName:"",
      register_set_point:863,
      register_set_point_acura:1537
    };
  },
  methods: {
    toggleEditTitleProgram() {
      if (this.titleProgram) {
        this.titleProgramBefore = this.titleProgram;
        this.titleProgram = "";
      }
      if (this.editTitleProgram) {
        this.titleProgram = this.titleProgramBefore;
      }
      this.editTitleProgram = !this.editTitleProgram;
    },
    toggleModal() {
      this.$refs["delete"].toggle("#modal-center-delete");
    },
    cleanData() {
      this.programName = "";
      this.minTempProgram = null;
      this.setPointProgram = null;
      this.maxTempProgram = null;
      this.titleProgram = "";
      this.selectedProgram = "";
      this.editRowData = "";
    },
     validationNew()
    {
      this.$refs.newForm.validate().then((success) => {
        if (success) {
          this.createProgram();
        }
      });
    },
    createProgram() {
      let data = {
        code: "",
        name: this.titleProgram,
        model: this.categories.find((model) => model.code == "8").code,
        min_temp: this.minTempProgram,
        max_temp: this.maxTempProgram,
        device: this.$route.params.id,
        set_point: this.setPointProgram,
      };
      this.$store
        .dispatch("recipes/createCustomRecipes", { context: this, data: data })
        .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Program Created",
                icon: "CheckIcon",
                variant: "success",
              },
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.data,
            },
          });
        });
      this.cleanData();
    },
    validationEdit()
    {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.updateProgram();
        }
      });
    },
    updateProgram() {
      let data = {
        name: this.editRowData.name,
        min_temp: this.editRowData.min_temp,
        max_temp: this.editRowData.max_temp,
        device: this.$route.params.id,
        code: this.editRowData.code,
        model: this.categories.find((model) => model.code == "8").code,
        set_point: this.editRowData.set_point
      };
      this.$store
        .dispatch("recipes/updateCustomRecipes", { context: this, data: data, recipe_id:this.editRowData.id})
        .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Program Changed",
                icon: "CheckIcon",
                variant: "success",
              },
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.data,
            },
          });
        });
      this.cleanData();
    },
    openModalEdit(params)
    {
      this.editRowData=params.row;
      console.log(this.editRowData)
      if(params.column.field != "mode")
        this.$refs['modal-center-edit'].show()

    },
    isCustomRecipe(recipe)
    {
      if(recipe.model == "8")
        return true
      else
        return false
    },
    deleteProgram() {
      let data = {
        device: this.$route.params.id,
      };
      this.$store
        .dispatch("recipes/deleteCustomRecipes", { context: this, recipe_id:this.editRowData.id, data:data})
        .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Program Changed",
                icon: "CheckIcon",
                variant: "success",
              },
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.data,
            },
          });
        });
      this.cleanData();
      this.$refs["modal-center-edit"].toggle("#modal-center-edit");
    },
    getTotalRecipes(recipe_name=null) {
      const recipeCustomEndpoint = `${process.env.VUE_APP_API_URL}api/recipes-custom/`
       let params = new URLSearchParams({
        device: this.$route.params.id,
        recipe_name: recipe_name ? recipe_name : "" 
      });
      let commitData = []
      this.$http.get(`${recipeCustomEndpoint}get-all-by-device/?${params.toString()}`)
      .then(res=>
      {
        let filteredrecipes = res.data.base_recipes.filter(x => x.category != "Blank");
        let customRecipes = res.data.custom_recipes.filter(x => x.category != "Blank");

        filteredrecipes.forEach((element) => {
          element["status"] = "OK";
          element[
            "temp_range"
          ] = (element.min_temp || element.min_temp == 0 ) ? `${element.min_temp}ºC to ${element.max_temp}ºC`: `${element.set_point}ºC`;
          element["is_set_point"] = element.set_point ? true:false;
          element["mode"] = element.code == this.currentRecipe ? "on" : "off";
        });

        customRecipes.forEach((element) => {
          element["status"] = element.synced ? "OK" : "";
          element[
            "temp_range"
          ] = (element.min_temp || element.min_temp == 0 ) ? `${element.min_temp}ºC to ${element.max_temp}ºC`: `${element.set_point}ºC`;
          element["is_set_point"] = element.set_point ? true:false;
          element["mode"] = element.code == this.currentRecipe ? "on" : "off";
        });
         commitData = Array.from(new Set([...filteredrecipes, ...customRecipes]));    
        })
        .then(()=>this.$store.commit("recipes/SET_FILTERED_RECIPES_LIST", commitData));
    },
    MinMaxRange(value)
    {
      if(value >= currentDevice.min_allowed_temp && value <= currentDevice.max_allowed_temp)
        return true
      else
        return false
    },
    setRecipeCommand()
    {
      if(this.isCustomRecipe(this.editRowData))
        this.register = 3074
      else
        this.register = 3072
      let data = {}
      if(this.editRowData.is_set_point){
        data = this.createCmdSetPoint()
      }
      else{
        data = this.createCmd()
      }
      console.log(data)
    //   let nameRecipe = this.editRowData.name
    //   const requestEndpoint = `${process.env.VUE_APP_API_URL}api/commands/`;
    //   this.$http.post(requestEndpoint, data)
    //   .then(() => 
    //   {
    //       let title = this.$t("Recipe Updated to") 
    //       this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: `${title} ${nameRecipe}`,
    //                 icon: 'CommandIcon',
    //                 variant: 'success',
    //                 text:this.$t("Processing command to new recipe")
    //               },
    //             });
    //       setTimeout(this.getCurrentDevice, 10000);
    //   })
    //   .catch( error => 
    //   {
    //         this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: this.$t('Error'),
    //                 icon: 'AlertTriangleIcon',
    //                 variant: 'danger',
    //                 text: error.data
    //               },
    //             }) 
    //   });;
    // this.cleanData()
    },
    createCmd()
    {
      let data = {
        "command":
        {
          "CMD": 1,
          "Data": {
            "Register": this.register,
            "Value": this.editRowData.code
          }
        },
        "device":this.currentDevice.mac_number,
        "description":`Set New Recipe ${this.editRowData.name}`
      }
      return data
    },
    createCmdSetPoint()
    {
      let data = {
        "command":
        {
          "CMD": 1,
          "Data": {
            "Register": this.currentDevice.typology == "Acura" ? this.register_set_point_acura:this.register_set_point,
            "Value": parseFloat((this.editRowData.set_point / 10).toFixed(1))
          }
        },
        "device":this.currentDevice.mac_number,
        "description":`Set New Set Point ${this.editRowData.name}`
      }
      return data
    },
    createCmdUpdateRecipes()
    {
      let data = {
        "command":
        {
          "CMD": 9,
          "Data": {
            "url": `${this.recipeS3}${this.currentDevice.mac_number}`,
            "file": "userprograms.csv"
          }
        },
        "device":this.currentDevice.mac_number,
        "description":"Sync Recipes"
      }
      return data
    },
    syncPrograms()
    {
      this.register = 3072
      let data = this.createCmdUpdateRecipes()
      this.$store.dispatch("recipes/syncCustomRecipes", { context: this, device: this.$route.params.id,})
      .then(()=>
      {
        const requestEndpoint = `${process.env.VUE_APP_API_URL}api/commands/`;
        this.$http.post(requestEndpoint, data)
        .then(() => 
        {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("Programs Synced Successfully"),
                      icon: 'CommandIcon',
                      variant: 'success',
                    },
                  });
        })
        .catch( error => 
        {
              this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("Error"),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: error.data
                    },
                  }) 
        });;
      });
    this.cleanData()
    },
    rangeTemp(min,max)
    {
      let listTemp = []
      for (let index = min; index < max + 1; index++) {
        listTemp.push(index)
      }
      return listTemp
    },
    getCurrentDevice() {
      this.$store.dispatch("devices/getSingleDevice", {
        context: this,
        id: this.$route.params.id,
      });
      this.getSingleDeviceRole()
      this.getGenericMetric()
    },
    getSingleDeviceRole(){
      this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    },
    getGenericMetric(){
      this.$store.dispatch("devices/getGenericDevicelMetric", {
      context: this,
      id: this.$route.params.id,
    });
    }
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    recipeS3()
    {
      return process.env.VUE_APP_S3_RECIPE;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        on: "light-activo",
        Off: "light-offline",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    categories() {
      return this.$store.state.recipes.categories;
    },
    totalRecipes()
    {
      return this.$store.state.recipes.filteredRecipes
    },
    currentDevice()
    {
      return this.$store.state.devices.currentDevice
    },
    currentDevicerRoles()
    {
      return this.$store.state.devices.currentDevicerRoles
    },
    currentRecipe()
    {
      return this.$store.state.devices.currentMetricGenericDevice.current_recipe ? this.$store.state.devices.currentMetricGenericDevice.current_recipe : 0
    },
    programsToSync()
    {
      return this.$store.state.recipes.filteredRecipes.filter(x=>x.status != "OK")
    },
    user() {
      return this.$store.state.users.currentUser;
    },
    btnDisabled() {
      let roleList = ["Mercatus Admin"];
      if (roleList.includes(Object.values(this.user.groups)[0])) return false;
      else if (this.user.id === this.currentDevicerRoles.owner || (this.user.id == this.currentDevicerRoles.technician || this.user.id == this.currentDevicerRoles.technician_remote  ) ) return false;
      else return true;
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
  },
  created() {
    setTimeout(() => {
      this.$store
        .dispatch("recipes/getRecipes", { context: this })
        .then(() => {
          return this.getCurrentDevice();
        })
        .then(() => {
          this.getTotalRecipes()
          this.$store.dispatch("recipes/getRecipeModel", { context: this });        
        });
    }, 500);
    
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>